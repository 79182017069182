div.col.align-self-center {
    margin: 100px auto;
}

Form {
    margin-top: 199px;
}

div.form-group {
    margin: 10px auto;
}

div.footer.container-fluid {
    margin-top: 12%;
}