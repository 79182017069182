html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(15, 32, 39, 0.7),
      rgba(44, 83, 100, 0.95));

  --image-gradient: linear-gradient(to bottom left,
      rgba(15, 32, 39, 0.8),
      rgba(40, 58, 86, 0.9));

  --imp-text-color: #00d9ff;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0b0d16;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #1a1c2c;
}

::-webkit-scrollbar-thumb {
  background: #00d9ff;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00c4e0;
  border-radius: 12px;
}

/* Navbar Section */
.sticky {
  background-color: #12141d !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.25) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 1000;
}

.navbar-toggler {
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #00d9ff !important;
  height: 4px !important;
  width: 27px !important;
  margin: 5px 0 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1rem !important;
    background-color: #181a27 !important;
  }

  .nav-link {
    padding: 0.7rem 1rem !important;
    font-size: 0.9rem;
  }

  .navbar-brand {
    font-size: 1.4rem;
  }

  .logo {
    height: 3em !important;
    width: 3em !important;
  }
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.navbar-brand {
  color: #ffffff !important;
}

.logo {
  height: 5em !important;
  width: 5em !important;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #00d9ff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* Home section */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 5em;
  text-align: center;
}

.home-section {
  background-position: top center;
  background-repeat: no-repeat;
  padding: 4rem 0 !important;
  margin-bottom: 0%;
  min-height: 100vh;
}

.home-content {
  padding: 4rem 1rem 2rem 1rem !important;
  color: #ffffff;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  color: #00d9ff;
}

.heading-name {
  font-size: 2.2em !important;
  color: #00d9ff;
}

.main-name {
  color: #00d9ff !important;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #00d9ff !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #00d9ff !important;
}

@media (max-width: 767px) {
  .home-header {
    padding-top: 3em;
    text-align: center;
  }

  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

/* Pour les grands écrans (desktops, laptops) */
@media (min-width: 1200px) {
  .home-about-section {
    padding-top: 60px !important;
    margin-top: -5%;
    /* Ajustez cette valeur pour un espacement adéquat */
  }

  .myAvtar {
    padding-top: 5em !important;
    padding-bottom: 3em !important;
  }
}

@media (width: 1024px) and (height: 600px) {
  .home-about-section {
    margin-top: 80% !important;
    padding-top: 60px !important;
  }

  .myAvtar {
    padding-top: 5em !important;
    padding-bottom: 3em !important;
  }

}

/* Pour les écrans moyens (tablettes, petits laptops) */
@media (min-width: 768px) and (max-width: 1199px) {
  .home-section {
    padding: 3rem 0 !important;
    margin-bottom: -90%;
  }

  .home-about-section {
    padding-top: 50px !important;
    margin-top: -7%;
    /* Ajustez cette valeur pour un espacement adéquat */
  }

  .myAvtar {
    padding-top: 4em !important;
    padding-bottom: 2.5em !important;
  }
}

/* Pour les écrans petits (mobiles, petits tablettes) */
@media (max-width: 767px) {
  .home-about-section {
    padding-top: 30px !important;
    margin-top: -10%;
    /* Ajustez cette valeur pour un espacement adéquat */
  }

  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

/* Pour les très petits écrans (mobiles en orientation portrait) */
@media (max-width: 480px) {
  .home-about-section {
    padding-top: 20px !important;
    margin-top: -12%;
    /* Ajustez cette valeur pour un espacement adéquat */
  }

  .myAvtar {
    padding-top: 1.5em !important;
    padding-bottom: 1.5em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  margin-top: -20%;
}

.home-about-description {
  color: #ffffff !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

@media (max-width: 767px) {
  .home-about-description {
    padding-top: 50px !important;
  }

  .home-about-body {
    padding-top: 25px;
    font-size: 1rem !important;
  }
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: #ffffff !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #00d9ff;
}

.home-social-icons:hover {
  color: #00d9ff;
  box-shadow: 0 0 5px #00d9ff;
  text-shadow: 0 0 2px #00d9ff;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #2c7ef8;
}

/* Footer */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
  color: #ffffff !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: #00d9ff !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #00d9ff !important;
}

/* Projects */
.project-section {
  position: relative !important;
  padding: 8rem 1rem !important;
}

.project-card {
  margin-bottom: 2rem;
  max-width: 300px;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(0, 217, 255, 0.3) !important;
  color: #ffffff !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

@media (max-width: 767px) {
  .project-heading {
    font-size: 1.5rem;
  }

  .project-card {
    max-width: 100%;
  }
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(0, 217, 255, 0.5) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(0, 217, 255, 0.3) !important;
  color: #ffffff !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: #ffffff !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(0, 217, 255, 0.5) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #ffffff !important;
  background-color: #00d9ff !important;
  border-color: #00d9ff !important;
}

.btn-primary:hover {
  color: #ffffff !important;
  background-color: #00c4e0 !important;
  border-color: #00c4e0 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: #ffffff !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
}

/* About */
.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  color: #ffffff !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(0, 217, 255, 0.5) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(0, 217, 255, 0.2) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
    font-size: 3.5em !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(0, 217, 255, 0.8) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: #ffffff !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* Resume */
.resume-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.resume {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #00d9ff !important;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #00d9ff !important;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #1a1c2c;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffffff !important;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #ffffff;
  border: 2px solid #00d9ff !important;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
  color: #ffffff !important;
}

.like-btn {
  background-color: #00d9ff5e !important;
  border-color: #00d9ff5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
  color: #ffffff !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #00c4e086 !important;
  border-color: #00c4e086 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
  margin-top: 20px;
  /* Espace entre les boutons */
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #00d9ff5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  color: #ffffff !important;
  display: inline-block;
  /* Garde les boutons en ligne */
}

/* Pour s'assurer que les boutons sont toujours centrés, quelle que soit la taille de l'écran */
@media (max-width: 767px) {
  .resume {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fork-btn {
    margin-top: 15px;
    /* Ajuste l'espacement sur les petits écrans */
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fork-btn {
    margin-top: 20px;
    /* Ajuste l'espacement pour les écrans moyens */
  }
}

@media (min-width: 1200px) {
  .resume {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fork-btn {
    margin-top: 25px;
    /* Ajuste l'espacement pour les grands écrans */
  }
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #00c4e086 !important;
  border-color: #00c4e086 !important;
}

.fork-btn-inner::after {
  display: none !important;
}