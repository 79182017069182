@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&family=Roboto+Mono:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Exo 2", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, #0f2027, #203a43, #2c5364);
}

html{
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: rgb(10, 4, 22);
  padding-top: 10px;
  padding-bottom: 8px;
  color: #ffffff;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}