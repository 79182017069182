/* Pour les très grands écrans (desktops) */
@media (min-width: 1200px) {
    div.resume-section.container-fluid {
        margin-top: 10%;
    }
}

/* Pour les grands écrans (laptops, desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
    div.resume-section.container-fluid {
        margin-top: 12%;
    }
}

/* Pour les écrans moyens (tablettes en mode paysage, petits laptops) */
@media (min-width: 768px) and (max-width: 991px) {
    div.resume-section.container-fluid {
        margin-top: 15%;
    }
}

/* Pour les petits écrans (tablettes, mobiles en mode paysage) */
@media (min-width: 576px) and (max-width: 767px) {
    div.resume-section.container-fluid {
        margin-top: 20%;
    }
}

/* Pour les très petits écrans (mobiles) */
@media (max-width: 575px) {
    div.resume-section.container-fluid {
        margin-top: 25%;
    }
}

/* Pour les très petits écrans en mode portrait */
@media (max-width: 480px) {
    div.resume-section.container-fluid {
        margin-top: 30%;
    }
}